/* eslint-disable no-undef */
import '../App.css';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import Countdown from 'react-countdown';
import { fantomTestnet } from 'wagmi/chains';
import React, { useEffect, useMemo, useState } from 'react';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import logo from '../assets/logo.png';
import bone from '../assets/bone.png';
import { ethers } from 'ethers';
import check from '../assets/check.gif';
import copy from '../assets/copy.png';
import fil from '../assets/fil.png';
import ethI from '../assets/ethereum.png';
import trueI from '../assets/checked.png';
import falseI from '../assets/record.png';
import Footer from '../Components/Footer';
import Web3 from 'web3';
import { filecoin } from 'wagmi/chains';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns

	if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

		window.location.reload();
		console.log("Mint Begins");
	}


	return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
	/*	}*/
};

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const ops = () => {
	window.open("#");
}

const discordL = () => {
	window.open("https://discord.gg/tknBv7Xwuj");
}

//WFIL
let ABI = [
	{
		"inputs": [],
		"name": "acceptOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_owner",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "CallFailed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "InsufficientFunds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "InvalidAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TimelockActive",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "Unauthorized",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Deposit",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "currentOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "pendingOwner",
				"type": "address"
			}
		],
		"name": "OwnershipPending",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "oldOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_depositor",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "recoverDeposit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revokeOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Withdrawal",
		"type": "event"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_a",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "recoveryTimelock",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let address = "0x60E1773636CF5E4A227d9AC24F20fEca034ee25A";

//WALLET
let ABIWallet = [
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "_wFILContractAddress",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Deposit",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "_to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "sendFILToAnotherWallet",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "sender",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "SentFIL",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "unwrapwFIL",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Withdrawal",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "wrapFIL",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wFILContractAddress",
		"outputs": [
			{
				"internalType": "address payable",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let addressWallet = "0x90863058b1B6714Ea3CfefD9B8643c57673158A8";

//TOKEN
let ABI2 = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "name_",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "symbol_",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "decimals_",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "initialBalance_",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_txFee",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_burnFee",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_FeeAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "tokenOwner",
				"type": "address"
			},
			{
				"internalType": "address payable",
				"name": "feeReceiver_",
				"type": "address"
			}
		],
		"stateMutability": "payable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "FeeAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Optimization",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "_owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "burnFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "tokenAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenAmount",
				"type": "uint256"
			}
		],
		"name": "recoverERC20",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "recipient",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "recipient",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "txFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_txFee",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_burnFee",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_FeeAddress",
				"type": "address"
			}
		],
		"name": "updateFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];

let address2 = "0x47F4A815032522A4BA912BB87Dc7eBD05eA54974";
//let address2 = "0xA30c27EE9Df6562B0990e8039be7DDCA6a9e12D0";

const Wallet = () => {

	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const [accountBalance, setAccountBalance] = useState(null);

	const { switchNetwork } = useSwitchNetwork()
	const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
	const [ethBalance, setEthBalance] = useState(null);
	const [walletAmount, setWalletAmount] = useState('');
	const [numConfirmationsRequired, setNumConfirmationsRequired] = useState();

	const [_transfer, set_transfer] = useState(0);
	const [_deposit, set_deposit] = useState(0);
	const [_send, set_send] = useState(0);
	const [_wrap, set_wrap] = useState(0);
	const [_unwrap, set_unwrap] = useState(0);
	const [_transfer2, set_transfer2] = useState(0);
	const [_newMultisig, set_newMultisig] = useState(0);
	const [_quantity, set_quantity] = useState('');
	const [rConfirmations, set_Rconfirmations] = useState('');
	const [_rewardRatePerSecond, setRewardRatePerSecond] = useState('');
	const [_address, set_Address] = useState('');
	const [_to, set_To] = useState('');
	const [_value, set_Value] = useState('');
	const [_sendingAmount, set_sendingAmount] = useState('');
	const [_owners, set_Owners] = useState([]);
	const [_confirmations, set_Confirmations] = useState('');
	const [_funds, set_Funds] = useState(0);
	const [_getProposalsByMultisig, set_getProposalsByMultisig] = useState([]);
	const [selectedProposal, setSelectedProposal] = useState(null);
	const [selectedAddress, setSelectedAddress] = useState(null); // state to track selected address
	const [balance, setBalance] = useState(0);
	const [_shibaBalanceMax, setShibaBalanceMax] = useState(0);
	const [_WFILMax, setWFILMax] = useState(0);
	const [_toConverted, set_toConverted] = useState('0x0000000000000000000000000000000000000000');
	const [_isFAddress, set_isFAddress] = useState(false);
	const [selectedCurrency, setSelectedCurrency] = useState('ETH');

	const handleMaxAmount = () => {
		fetchShibaMax();
	};

	const wrapMax = () => {
		WFILMax();
	};

	const myBalanceMax = () => {
		myWalletMax();
	};

	const handleChange = (event) => {
		// Ensure only positive numbers or an empty string are allowed
		const newValue = event.target.value;
		if (!isNaN(newValue) && parseFloat(newValue) >= 0) {
			set_Value(newValue);
		}
	};


	const handleAddressClick = (address) => {
		setSelectedAddress(address);
	};

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const defaultWalletAddress = "0x0000000000000000000000000000000000000000";

	// Use default value if walletAddress is undefined
	const actualWalletAddress = walletAddress || defaultWalletAddress;

	const publicClient = createPublicClient({
		chain: filecoin,
		transport: http()
	})

	const [statusError, setstatusError] = useState(false)
	const [statusLoading, setstatusLoading] = useState(false)
	const [success, setsuccess] = useState("")
	const [_navbarOpen, set_navbarOpen] = useState(0)
	const [_connected, setConnected] = useState(false)
	const [_receive, set_receive] = useState(0);
	const [_shibaBalance, setShibaBalance] = useState(0);
	const [_balanceWFIL, setBalanceWFIL] = useState(0);
	const [_currentFetchingAddress, setCurrentFetchingAddress] = useState('0x0000000000000000000000000000000000000000');
	const [_loadProposals, setLoadProposals] = useState(false);
	const [_filAddress, setFilAddress] = useState('');


	const [_convertedAddress, set_convertedAddress] = useState('');
	//const HEX_CHARS = '0123456789abcdefABCDEF';
	const { newDelegatedEthAddress } = require('@glif/filecoin-address');
	const { ethAddressFromID } = require('@glif/filecoin-address');
	const { ethAddressFromDelegated } = require('@glif/filecoin-address');
	//const ethAddress = ethAddressFromDelegated('f410fcahujoxwcixd5nrcn7qzwzlepkyu77yfesyzuoq');
	//console.log("normalAdd : " + ethAddress.toString());


	async function closeNav() {
		set_navbarOpen(0);
		//this.setState({ _navbarOpen: 0 });
	}

	async function closeWindow2() {

		setstatusError(false);
		set_newMultisig(0);
	}


	async function closeWindow3() {

		set_transfer(0);
		set_deposit(0);
		set_wrap(0);
		set_unwrap(0);
		set_transfer2(0);
		set_send(0);

	}

	async function closeWindow4() {

		set_newMultisig(0);
	}

	async function navbarOpen() {
		set_navbarOpen(1);
		//this.setState({ _navbarOpen: 0 });
	}

	//........................................//
	var contractWFIL = {
		address: address,
		abi: ABI
	}

	var contract2 = {
		address: address2,
		abi: ABI2
	}

	var contract3 = {
		address: addressWallet,
		abi: ABIWallet
	}

	async function handleConnect() {
		if (chain.id !== 314) {
			switchNetwork(314)
		}

		setConnected(true);
	}

	const { refetch: getAllowance } = useContractRead({
		...contract2,
		functionName: 'allowance', args: [actualWalletAddress, address]
	})

	const { refetch: getBalance } = useContractRead({
		...contract2,
		functionName: 'balanceOf',
		args: [actualWalletAddress]
	})

	const { refetch: getBalanceWFIL } = useContractRead({
		...contractWFIL,
		functionName: 'balanceOf',
		args: [actualWalletAddress]
	})

	async function settingAddress(address) {

		setCurrentFetchingAddress(address);
		setSelectedAddress(address);

		await new Promise(resolve => setTimeout(resolve, 500));
		console.log("settingUpAddress" + address);

	}

	/*async function fetchGetProposalsByMultisig() {
		var data1 = await getProposalsByMultisig();

		// Check if data1.data is defined and not empty
		if (data1.data && data1.data.length > 0) {
			// Access the properties of the first object in the array
			console.log("set_getProposalsByMultisig2 : " + data1.data[0].numConfirmationsRequired);
		} else {
			console.log("Data is empty or undefined");
		}

		await new Promise(resolve => setTimeout(resolve, 500));
		console.log("fetched" + data1.data);
		console.log("address" + address);
	}*/

	async function fetchShibaMax() {
		var data3 = await getBalance();
		setShibaBalanceMax(Number(data3.data) / 10 ** 18);
		set_Value(Number(data3.data) / 10 ** 18);
	}

	async function WFILMax() {
		var data3 = await getBalanceWFIL();
		setWFILMax(Number(data3.data) / 10 ** 18);
		set_Value(Number(data3.data) / 10 ** 18);
	}

	async function myWalletMax() {
		set_Value(balance);
	}

	async function changeInput() {

		if (_to && _to[0] === 'f') {
			set_isFAddress(true);
			const convertedAddress = newDelegatedEthAddress(_to);
			set_toConverted(convertedAddress.toString());
			console.log("Converted Address: " + convertedAddress);
			console.log("To Address: " + _to);
		} else {
			set_isFAddress(false);
			setFilAddress(_to);

			console.log("Converted Address Else: " + _to);
		}
	}

	useEffect(() => {

		/*if (actualWalletAddress) {
			getAccountBalance();
		}*/
		try {
			async function fetchBalance() {
				if (window.ethereum) {
					// Initialize web3 with the Metamask provider
					const web3 = new Web3(window.ethereum);
					try {
						// Request account access if needed
						await window.ethereum.request({ method: 'eth_requestAccounts' });
						// Get the current account address
						const accounts = await web3.eth.getAccounts();
						const account = accounts[0];
						// Get the balance of the account
						const balance = await web3.eth.getBalance(account);
						// Convert balance from Wei to Ether
						const etherBalance = web3.utils.fromWei(balance, 'ether');
						// Set the balance state
						setBalance(etherBalance);
					} catch (error) {
						console.error('Error fetching balance:', error);
					}
				} else {
					console.error('Metamask not installed');
				}
			}


			async function currentFetchingAddress() {
				if (_currentFetchingAddress == null) {
					setLoadProposals(false);
					console.log("_currentFetchingAddressFALSE :" + _currentFetchingAddress);
				} else {
					setLoadProposals(true);
					console.log("_currentFetchingAddressTRUE :" + _currentFetchingAddress.toString());

				}
			}

			async function fetchShiba() {
				var data3 = await getBalance();

				setShibaBalance(Number(data3.data) / 10 ** 18);
				console.log("setShibaBalance : " + data3.data)
			}


			async function fetchWFIL() {
				var data3 = await getBalanceWFIL();

				setBalanceWFIL(Number(data3.data) / 10 ** 18);
			}

			set_receive((_quantity * _rewardRatePerSecond * 86400)/10000);

			if (_connected) {
				delegatedEth();

				currentFetchingAddress();
				fetchShiba();
				fetchBalance();
				fetchWFIL();
			}

			//changeInput();

		} catch (err) {
			console.log(err)
		}
		// eslint-disable-next-line no-use-before-define

		// eslint-disable-next-line no-use-before-define
	}, [actualWalletAddress, _connected, _quantity, numConfirmationsRequired, _to
		  /*getRewardRatePerSecond, _rewardRatePerSecond, _receive*/]);

	/*const { data, refetch, isSuccess } = useContractReads({
		contracts: [
			{ ...contract, functionName: 'totalSupply', args: [0] },
			{ ...contract, functionName: 'max_per_wallet' },
			{ ...contract, functionName: 'publicSaleCost', args: [0] },
			//{ ...contract, functionName: 'public_mint_status' }

		]
	},)*/

	/*useMemo(() => {

		if (isSuccess === true) {
			console.log("isSuccess : " + isSuccess);
			//settotalSupply(Number(data[0].result));
			settotalSupply(Number(data[0].result));
			console.log("settotalSupply : " + settotalSupply);
			//set_max_per_wallet(Number(data[1].result));
			set_max_per_wallet(Number(data[1].result));
			console.log("set_max_per_wallet : " + set_max_per_wallet);
			//set_costGateway(Number(data[4].result) / 10 ** 4); // Convert WEI to ETH
			set_costGateway(Number(data[4].result) / 10 ** 4); // Convert WEI to ETH
			console.log("set_costGateway : " + set_costGateway);
			//set_public_mint_status(data[5].result);
		}
	}, [_totalSupply, data, isSuccess])*/

	/*async function getAccountBalance() {
		try {
			// Create a new Web3Provider using window.ethereum
			let provider = new ethers.providers.Web3Provider(window.ethereum);

			// Fetch account balance
			let balance = await provider.getBalance(actualWalletAddress);

			// Format balance from Wei to Ether
			balance = ethers.utils.formatEther(balance);

			// Set account balance in state
			setAccountBalance(balance.substring(0, 4));
		} catch (error) {
			console.error('Error fetching account balance:', error);
		}
	}*/

	const { writeAsync } = useContractWrite({
		//...contract,
		//functionName: 'mint',
		onError(error) {
			if (error.message.includes('balance')) {
				setstatusError(true)
				setstatusLoading(false)
			}
		}
	})

	//........................................//

	async function delegatedEth() {
		console.log("one");
		if(actualWalletAddress !== '0x0000000000000000000000000000000000000000'){
		const filAddress = newDelegatedEthAddress(actualWalletAddress);
		setFilAddress(filAddress);

		}else{
			setFilAddress("");
		}
		console.log("Two");

		console.log("Three");
	}

	async function _depositOpen() {
		set_deposit(1);
	}

	async function sendFIL() {
		set_send(1);
	}

	async function _transferOpen() {
		set_transfer(1);
	}

	async function wrapOpen() {
		set_wrap(1);
	}

	async function unwrapOpen() {
		set_unwrap(1);
	}

	async function transfer2Open() {
		set_transfer2(1);
	}

	async function newMultisigOpen() {
		set_newMultisig(1);
	}

	async function withdrawWFIL() {

		try {
			setstatusLoading(true);
			var res = await writeAsync({
				...contractWFIL,
				functionName: 'withdraw',
				args: [Number(_value * 10 ** 9)],
				//value: '100000000000000000',
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function transfer() {

		try {
			setstatusLoading(true);
			var res = await writeAsync({
				...contract2,
				functionName: 'transfer',
				args: [_to, Number(_value * 10 ** 18)],
				//value: '100000000000000000',
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function transfer2() {

		try {

			const convertedAddress = ethAddressFromDelegated(_to);
			//console.log("normalAdd : " + ethAddress.toString());

			//set_toConverted(convertedAddress.toString());
			await new Promise(resolve => setTimeout(resolve, 2000));

			console.log("Converted Address: " + convertedAddress);
			console.log("To Address: " + _to);

			setstatusLoading(true);
			var res = await writeAsync({
				...contract2,
				functionName: 'transfer',
				args: [convertedAddress, Number(_value * 10 ** 18)],
				//value: '100000000000000000',
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function wrapWFIL() {

		try {
			setstatusLoading(true);
			var res = await writeAsync({
				...contract3,
				functionName: 'wrapFIL',
				args: [parseEther(_value)],
				value: parseEther(_value),
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function transferWFIL() {

		try {
			setstatusLoading(true);
			var res = await writeAsync({
				...contractWFIL,
				functionName: 'transfer',
				args: [_to, parseEther(_value)],
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function transferWFIL2() {

		try {
			const convertedAddress = ethAddressFromDelegated(_to);
			//console.log("normalAdd : " + ethAddress.toString());

			//set_toConverted(convertedAddress.toString());
			await new Promise(resolve => setTimeout(resolve, 2000));

			console.log("Converted Address: " + convertedAddress);
			console.log("To Address: " + _to);

			setstatusLoading(true);
			var res = await writeAsync({
				...contractWFIL,
				functionName: 'transfer',
				args: [convertedAddress, parseEther(_value)],
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function transferFIL() {

		try {
			setstatusLoading(true);
			var res = await writeAsync({
				...contract3,
				functionName: 'sendFILToAnotherWallet',
				args: [_to, parseEther(_value)],
				value: parseEther(_value),
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function transferFIL2() {

		try {

			//if (_to && _to[0] === 'f') {
			//set_isFAddress(true);

			const convertedAddress = ethAddressFromDelegated(_to);
			//console.log("normalAdd : " + ethAddress.toString());

			//set_toConverted(convertedAddress.toString());
			await new Promise(resolve => setTimeout(resolve, 2000));

			console.log("Converted Address: " + convertedAddress);
			console.log("To Address: " + _to);

			setstatusLoading(true);
			var res = await writeAsync({
				...contract3,
				functionName: 'sendFILToAnotherWallet',
				args: [convertedAddress, parseEther(_value)],
				value: parseEther(_value),
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function approveProposal() {

		try {
			setstatusLoading(true);
			var res = await writeAsync({
				...contract3,
				functionName: 'approveProposal',
				args: [_currentFetchingAddress, selectedProposal],
				//value: '100000000000000000',
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function executeProposal() {

		try {
			setstatusLoading(true);
			var res = await writeAsync({
				...contract3,
				functionName: 'executeProposal',
				args: [_currentFetchingAddress, Number(selectedProposal)],
				//value: '100000000000000000',
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	const isExceedingStakedAmount = () => {
		const enteredAmount = Number(_quantity);
		console.log("entered Amount : " + enteredAmount);
		console.log("_quantity Amount : " + _stakedAmount);
		return enteredAmount * 10 ** 18 > _stakedAmount;
	};

	function shortenAddress(filAddress) {
		return filAddress.slice(0, 6) + "..." + filAddress.slice(-4);
	}

	function shortenAddress2(actualWalletAddress) {
		return actualWalletAddress.slice(0, 7) + "..." + actualWalletAddress.slice(-4);
	}

	function generateScanUrl(address) {
		return `https://testnet.ftmscan.com/address/${address}`;
	}

	const handleCurrencyChange = (event) => {
		setSelectedCurrency(event.target.value);
	};

	return (
		<>
			<div class="allWrap2">
				<div class="light">

					<div class="headers">

						<div class="h1">
							<div class="logoDiv">
								<img onClick={() => window.location.href = '/'} src={logo} />

								<div class="connect">
									<div onClick={() => window.location.href = 'stake'} id="fontSize">Stake</div>
									<div onClick={() => window.location.href = 'multisig'} id="fontSize" >Multisig</div>
									<div onClick={() => window.location.href = 'wallet'} id="fontSize" ><span className='activeNav'>Wallet</span></div>
									<div id="fontSize" >FAQ</div>
								</div>

							</div>

							<div className='web3'><Web3Button /></div>

						</div>

					</div>

					{_navbarOpen < 1 ?
						(<div class="miniBarMain">
							<div class="logoDiv"><img src={logo} /></div>

							<div className='web3'><Web3Button /></div>

							<div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
						</div>) :
						(<div class="miniBarMain">
							<div class="logoDiv"><img src={logo} /></div>

							<div className='web3'><Web3Button /></div>

							<div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
						</div>)}

					{_navbarOpen > 0 ?
						(<div class="littleNav">
							<div onClick={() => window.location.href = 'stake'} id="fontSize">Stake</div>
							<div onClick={() => window.location.href = 'multisig'} id="fontSize" >Multisig</div>
							<div onClick={() => window.location.href = 'wallet'} id="fontSize" ><span className='activeNav'>Wallet</span></div>
							<div id="fontSize" >FAQ</div>
						</div>) : null}


					<div className='multisigMain-1'>
						<div className='multisigMain'>
							<div className='section1MWallet'>
								<div className='multisigMain2'>

									<div className='m1'>Accounts</div>

									{actualWalletAddress === undefined ?
										<div>
											<div className='m2'>Connect your wallet to access your accounts</div>
											<button className='buttonM' onClick={open}>Connect Wallet</button>
										</div>
										:

										<div className='wallet'>
											<img className='icon2' src={ethI} />
											<div className='walletAdd'>{actualWalletAddress !== "0x0000000000000000000000000000000000000000" ? shortenAddress2(actualWalletAddress):shortenAddress2("")} </div></div>
									}

								</div>
							</div>

							<div className='section2M'>
								<Element name="addresses">

									<div className='box2M'>
										{actualWalletAddress === undefined ?
											<div className='signerSection-2'>
												<div><span className='multisigH'>Disconnected</span></div>
											</div> :
											<div>
												<div className='signerSection-2'>
													<div><span className='multisigH'>Connected</span></div>

													<div className='flexBtns'>
														{/*<div><button onClick={_depositOpen} className='btnColor'>Deposit to Pool</button></div>*/}
														<div><button onClick={sendFIL}>Send FIL</button></div>
													</div>
												</div>

												<div className='iconsAddressMain'>
													<div className='iconsAddress'>
														<img className='icon' src={fil} />
														<div
															onClick={() => {
																// Copy address to clipboard and handle notification
																navigator.clipboard.writeText(_filAddress)
																	.then(() => {
																		const notification = document.getElementById('copy-notification');
																		notification.classList.add('show');
																		setTimeout(() => notification.classList.remove('show'), 2000); // Hide after 2 seconds
																	})
																	.catch(error => console.error('Failed to copy address:', error));

																// Optional: Handle address selection
																settingAddress(address);
															}}
														>
															<div style={{ cursor: 'copy' }} // Set cursor style for visual indication
																title="Click to copy" >{shortenAddress(_filAddress.toString())} <img className='copyImg' src={copy} />
															</div>

														</div>
													</div>

													<div className='iconsAddress'>
														<img className='icon' src={ethI} />
														<div
															onClick={() => {
																// Copy address to clipboard and handle notification
																navigator.clipboard.writeText(actualWalletAddress)
																	.then(() => {
																		const notification = document.getElementById('copy-notification');
																		notification.classList.add('show');
																		setTimeout(() => notification.classList.remove('show'), 2000); // Hide after 2 seconds
																	})
																	.catch(error => console.error('Failed to copy address:', error));

																// Optional: Handle address selection
																settingAddress(address);
															}}
														>
															<div style={{ cursor: 'copy' }} // Set cursor style for visual indication
																title="Click to copy" >{actualWalletAddress !== "0x0000000000000000000000000000000000000000" ? shortenAddress2(actualWalletAddress):shortenAddress2("")} <img className='copyImg' src={copy} />
															</div>

														</div>
													</div>

												</div>
											</div>
										}

										<div className='amountDetailsMain'>
											<div className='amountDetails'>
												<div className='balanceD'>Balance</div>
												<div className='amountD'>{Number(balance).toFixed(2)} FIL</div>
											</div>

											<div className='amountDetails'>
												<div className='balanceD'>FSHIB balance</div>
												<div className='amountD'>{Number(_shibaBalance).toFixed(2)} FSHIB</div>
											</div>
										</div>
									</div>

									<div className='box2M'>
										<div><span className='multisigH'>Tokens</span></div>

										<div className='smallH'></div>

										<div className='amountDetails2'>
											<div className='signerSection'>
												<div><span className='filAmount'>FSHIB: {Number(_shibaBalance).toFixed(2)} FSHIB</span></div>

												<div className='flexBtns'>
													<div><button className='smallBtns' onClick={_transferOpen}>Transfer</button></div>
												</div>
											</div>

										</div>

										<p></p>

										<div className='amountDetails2'>
											<div className='signerSection'>
												<div><span className='filAmount'>WFIL: {_balanceWFIL} WFIL</span></div>

												<div className='flexBtns'>
													<div><button className='smallBtns' onClick={wrapOpen}>Wrap</button></div>
													<div><button className='smallBtns' onClick={unwrapOpen}>Unwrap</button></div>
													<div><button className='smallBtns' onClick={transfer2Open}>Transfer</button></div>
												</div>
											</div>

										</div>
									</div>
								</Element>

								{/*<div className='box2M'>
									<div className='signerSection'>
										<div><span className='multisigH'>Transactions</span></div>
									</div>

									<div class="table-container">

										<table>
											<thead>
												<tr>
													<th>DATE</th>
													<th>CID</th>
													<th>FROM</th>
													<th>TO</th>
													<th>METHOD</th>
													<th>AMOUNT</th>
												</tr>
											</thead>

										</table>

									</div>
									</div>*/}

							</div>

						</div>

					</div>

				</div>

				<div id="copy-notification" className="hidden">
					Copied!
				</div>
				<Footer />
			</div>

			{_send > 0 ?
				<div class="formMain2-2">
					<form onClick={closeWindow3}>
						<button class="closeNote">✖</button>
					</form>
					<div class="formMain-2-2">

						<div class="input-container-Main">

							<p className='inP1'>Send FIL</p>
							<p className='inP2'>Please enter the details of your transaction below</p>

							<div className='myBalance'>
								<div>
									<div>From</div>
									<div>{shortenAddress2(actualWalletAddress)}</div>
								</div>

								<div>
									<div><span className='balanceRight'>Balance</span></div>
									<div className='filA'>{Number(balance).toFixed(2)} FIL</div>
								</div>
							</div>

							<div class="input-container">
								<div>To:</div>
								<div className='filAndAmount'>
									{selectedCurrency === 'ETH' ?
										<input type="text" id="to" name="to" placeholder="Enter recipient address..."
											value={_to}
											onChange={event => set_To(event.target.value)}
										/> :
										<input type="text" id="to" name="to" placeholder="Enter recipient address..."
											value={_to}
											onChange={event => set_To(event.target.value)}
										/>
									}

									<div className='inputDeposit2'>
										{selectedCurrency === 'ETH' && <img src={ethI} alt="ETH" />}
										{selectedCurrency === 'FIL' && <img src={fil} alt="FIL" />}
										<select value={selectedCurrency} onChange={handleCurrencyChange}>
											<option value="ETH">ETH</option>
											<option value="FIL">FIL</option>
										</select>
									</div>


								</div>
							</div>

							<div class="input-container">
								<div>Amount:</div>
								<div className='filAndAmount'>
									<input
										id="value"
										type='number'
										name="value"
										placeholder="Enter value..."
										value={_value}
										onChange={handleChange}
									/>

									<button className='max' onClick={myBalanceMax}>MAX</button>

									<div className='filB'>FIL</div>
								</div>

							</div>
							{/*!_isFAddress ?
								<button className='buttonM' onClick={transferFIL}>Send</button> :
								<button className='buttonM' onClick={transferFIL2}>Send</button>
								*/}

							{selectedCurrency === 'ETH' ?
								<button className='buttonM' onClick={transferFIL}>Send</button> :
								<button className='buttonM' onClick={transferFIL2}>Send</button>
							}


						</div>
					</div>
				</div> : null
			}

			{_deposit > 0 ?
				<div class="formMain2-2">
					<form onClick={closeWindow3}>
						<button class="closeNote">✖</button>
					</form>
					<div class="formMain-2-2">

						<div class="input-container-Main">

							<p className='inP1'>Deposit in the Infinity Pool</p>
							<p className='inP2'>Please enter the details of your transaction below</p>

							<div className='myBalance'>
								<div>
									<div>From</div>
									<div>{shortenAddress2(actualWalletAddress)}</div>
								</div>

								<div>
									<div><span className='balanceRight'>Balance</span></div>
									<div className='filA'>{Number(balance).toFixed(2)} FIL</div>
									<div className='filA'>{Number(_shibaBalance).toFixed(2)} FSHIB</div>
								</div>
							</div>

							<div class="input-container">
								<div>To:</div>
								<input type="text" id="to" name="to" placeholder="Enter recipient address..."
									value={_to}
									onChange={event => set_To(event.target.value)}
								/>
							</div>

							<div class="input-container">
								<div>Amount:</div>
								<div className='filAndAmount'>
									<input id="value" type='number' name="value" placeholder="Enter value..."
										value={_value}
										onChange={event => set_Value(event.target.value)}
									/>
									<button className='max'>MAX</button>

									<div className='filB'>FIL</div>
								</div>

							</div>
							<button className='buttonM' >Send</button>
						</div>
					</div>
				</div> : null
			}

			{_transfer > 0 ?
				<div class="formMain2-2">
					<form onClick={closeWindow3}>
						<button class="closeNote">✖</button>
					</form>
					<div class="formMain-2-2">

						<div class="input-container-Main">

							<p className='inP1'>Transfer FSHIB</p>
							<p className='inP2'>Please enter the details of your transaction below</p>

							<div className='myBalance'>
								<div>
									<div>From</div>
									<div>{shortenAddress2(actualWalletAddress)}</div>
								</div>

								<div>
									<div><span className='balanceRight'>Balance</span></div>
									<div className='filA'>{Number(balance).toFixed(2)} FIL</div>
									<div className='filA'>{Number(_shibaBalance).toFixed(2)} FSHIB</div>
								</div>
							</div>

							<div class="input-container">
								<div>To:</div>
								<div className='filAndAmount'>
									<input type="text" id="to" name="to" placeholder="Enter recipient address..."
										value={_to}
										onChange={event => set_To(event.target.value)}
									/>

									<div className='inputDeposit2'>
										{selectedCurrency === 'ETH' && <img src={ethI} alt="ETH" />}
										{selectedCurrency === 'FIL' && <img src={fil} alt="FIL" />}
										<select value={selectedCurrency} onChange={handleCurrencyChange}>
											<option value="ETH">ETH</option>
											<option value="FIL">FIL</option>
										</select>
									</div>
								</div>
							</div>

							<div class="input-container">
								<div>Amount:</div>
								<div className='filAndAmount'>
									<input
										id="value"
										type='number'
										name="value"
										placeholder="Enter value..."
										value={_value}
										onChange={handleChange}
									/>

									<button className='max' onClick={handleMaxAmount}>MAX</button>

									<div className='filB'>FSHIB</div>
								</div>

							</div>
							{selectedCurrency === 'ETH' ?
								<button className='buttonM' onClick={transfer}>Send</button> :
								<button className='buttonM' onClick={transfer2}>Send</button>
							}
						</div>
					</div>
				</div> : null
			}

			{_wrap > 0 ?
				<div class="formMain2-2">
					<form onClick={closeWindow3}>
						<button class="closeNote">✖</button>
					</form>
					<div class="formMain-2-2">

						<div class="input-container-Main">

							<p className='inP1'>Wrap FIL</p>
							<p className='inP2'>Please enter the details of your transaction below</p>

							<div className='myBalance'>
								<div>
									<div>From</div>
									<div>{shortenAddress2(actualWalletAddress)}</div>
								</div>

								<div>
									<div><span className='balanceRight'>Balance</span></div>
									<div className='filA'>{Number(balance).toFixed(2)} FIL</div>
									<div className='filA'>{Number(_shibaBalance).toFixed(2)} FSHIB</div>
								</div>
							</div>

							<div class="input-container">
								<div>Amount:</div>
								<div className='filAndAmount'>
									<input
										id="value"
										type='number'
										name="value"
										placeholder="Enter value..."
										value={_value}
										onChange={handleChange}
									/>

									<button className='max' onClick={handleMaxAmount}>MAX</button>


									<div className='filB'>WFIL</div>
								</div>

							</div>
							<button className='buttonM' onClick={wrapWFIL}>Send</button>
						</div>
					</div>
				</div> : null
			}

			{_unwrap > 0 ?
				<div class="formMain2-2">
					<form onClick={closeWindow3}>
						<button class="closeNote">✖</button>
					</form>
					<div class="formMain-2-2">

						<div class="input-container-Main">

							<p className='inP1'>Unwrap WFIL</p>
							<p className='inP2'>Please enter the details of your transaction below</p>

							<div className='myBalance'>
								<div>
									<div>From</div>
									<div>{shortenAddress2(actualWalletAddress)}</div>
								</div>

								<div>
									<div><span className='balanceRight'>Balance</span></div>
									<div className='filA'>{Number(balance).toFixed(2)} FIL</div>
									<div className='filA'>{Number(_shibaBalance).toFixed(2)} FSHIB</div>
								</div>
							</div>

							<div class="input-container">
								<div>Amount:</div>
								<div className='filAndAmount'>
									<input
										id="value"
										type='number'
										name="value"
										placeholder="Enter value..."
										value={_value}
										onChange={handleChange}
									/>
									<button className='max' onClick={wrapMax}>MAX</button>

									<div className='filB'>WFIL</div>
								</div>

							</div>
							<button className='buttonM' onClick={withdrawWFIL}>Send</button>
						</div>
					</div>
				</div> : null
			}

			{_transfer2 > 0 ?
				<div class="formMain2-2">
					<form onClick={closeWindow3}>
						<button class="closeNote">✖</button>
					</form>
					<div class="formMain-2-2">

						<div class="input-container-Main">

							<p className='inP1'>Transfer WFIL</p>
							<p className='inP2'>Please enter the details of your transaction below</p>

							<div className='myBalance'>
								<div>
									<div>From</div>
									<div>{shortenAddress2(actualWalletAddress)}</div>
								</div>

								<div>
									<div><span className='balanceRight'>Balance</span></div>
									<div className='filA'>{Number(balance).toFixed(2)} FIL</div>
									<div className='filA'>{Number(_shibaBalance).toFixed(2)} FSHIB</div>
								</div>
							</div>

							<div class="input-container">
								<div>To:</div>
								<div className='filAndAmount'>
									<input type="text" id="to" name="to" placeholder="Enter recipient address..."
										value={_to}
										onChange={event => set_To(event.target.value)}
									/>
									<div className='inputDeposit2'>
										{selectedCurrency === 'ETH' && <img src={ethI} alt="ETH" />}
										{selectedCurrency === 'FIL' && <img src={fil} alt="FIL" />}
										<select value={selectedCurrency} onChange={handleCurrencyChange}>
											<option value="ETH">ETH</option>
											<option value="FIL">FIL</option>
										</select>
									</div>
								</div>
							</div>

							<div class="input-container">
								<div>Amount:</div>
								<div className='filAndAmount'>
									<input
										id="value"
										type='number'
										name="value"
										placeholder="Enter value..."
										value={_value}
										onChange={handleChange}
									/>
									<button className='max' onClick={wrapMax}>MAX</button>

									<div className='filB'>WFIL</div>

								</div>

							</div>
							{selectedCurrency === 'ETH' ?
								<button className='buttonM' onClick={transferWFIL}>Send</button> :
								<button className='buttonM' onClick={transferWFIL2}>Send</button>
							}
						</div>
					</div>
				</div> : null
			}


			<div>

				{statusError ?
					(<div class="formMain2">
						<form onClick={closeWindow2}>
							<button class="closeNote">✖</button>
						</form>
						<div class="formMain-2">

							<div>
								<div class="errorMessage">
									Sorry, something went wrong please try again later
								</div>
							</div>
						</div>

					</div>) : null}

				{statusLoading ?
					(<div class="formMain2">
						<div class="formMain-2">

							<div class="loader"></div>

						</div>

					</div>) : null}

				{success ?
					(<div class="formMain2">
						<div class="formMain-2">

							<div>
								<img class="check" src={check} />

								<div class="success-message">
									<h2>Transaction Successful!</h2>
									<h2>Awaiting updates...</h2>
								</div>
							</div>
						</div>


					</div>) : null}

			</div>

		</>
	);

}
export default Wallet;
