import React, { useEffect, useState } from 'react';

const App2 = () => {

  const [_convertedAddress, set_convertedAddress] = useState('0x100f44BaF6122E3eB6226FE19B65647AB14FfF05');

  const HEX_CHARS = '0123456789abcdefABCDEF';

  const {newDelegatedEthAddress} = require('@glif/filecoin-address')
  const {ethAddressFromID} = require('@glif/filecoin-address')

  const filAddress = newDelegatedEthAddress('0x100f44BaF6122E3eB6226FE19B65647AB14FfF05');
  console.log("filAddress : " + filAddress.toString());

  const {ethAddressFromDelegated} = require('@glif/filecoin-address')

  const ethAddress = ethAddressFromDelegated('f410fcahujoxwcixd5nrcn7qzwzlepkyu77yfesyzuoq');
  console.log("normalAdd : " + ethAddress.toString());

  useEffect(() => {

    function validateHexString(hexString) {
      const strLen = hexString.length;
      let countOfOddChars = 0;
  
      // Check length is even or odd
      if ((strLen % 2) !== 0) return false;
  
      // Validate first two chars are either "0x" or "0X"
      if (!(['0x', '0X'].includes(hexString.substring(0, 2)))) return false;
  
      // Loop through string and check if only contain valid hex chars
      for (let i = 0; i < strLen; i++) {
        if (!HEX_CHARS.includes(hexString[i])) return false;
  
        if (i % 2 === 1 && parseInt(hexString.slice(i, i + 2), 16) >= 0x80) {
          countOfOddChars++;
        }
      }
  
      return countOfOddChars % 2 === 0;
    }
  
    function ethAddressToString(ethAddress) {
      const cleanedAddress = ethAddress.toLowerCase().replace(/^0x/, "");
      if (!validateHexString(cleanedAddress)) return null;
  
      const chunks = [];
      for (let i = 0; i < cleanedAddress.length; i += 4) {
        chunks.push(cleanedAddress.substr(i, 4));
      }
  
      let result = "";
      for (let j = 0; j < chunks.length; j++) {
        const num = Number("0x" + chunks[j]).toString(16);
        const val = String.fromCharCode(parseInt(num, 16));
        result += val;
      }
  
      set_convertedAddress(result);    
      console.log("result : " + result);
    }
		
    ethAddressToString('0x100f44BaF6122E3eB6226FE19B65647AB14FfF05');
		// eslint-disable-next-line no-use-before-define
	}, []);


  return (
    <div className="allWrap">
      <div className="light">
        <header className="App-header">
          <h1>ETH Address Converter Example:</h1>
          {_convertedAddress}
        </header>
      </div>
    </div>
  );
}

export default App2;