import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/home";
import Stake from "./Pages/stake";
import Multisig from "./Pages/multisig";
import App2 from "./Pages/App2";
import Wallet from "./Pages/wallet";

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
					<Route path='/stake' element={<Stake />} />
					<Route path='/multisig' element={<Multisig />} />
					<Route path='/app2' element={<App2 />} />
					<Route path='/wallet' element={<Wallet />} />
				
				</Routes>
			</BrowserRouter>
			<div>


			</div>
		</div>




	)
}


export default App;
