import React, { useState, useEffect } from 'react';

const Footer = () => {

    return (
        <>
            <footer class="footer">
                <div class="footer-content">
                    <div class="footer-logo">FSHIB</div>
                    <ul class="footer-nav">
                        <li><a onClick={() => window.location.href = '/'}>Home</a></li>
                        <li><a onClick={() => window.location.href = 'stake'}>Stake</a></li>
                        <li><a onClick={() => window.location.href = 'multisig'}>Multisig</a></li>
                        <li><a onClick={() => window.location.href = 'faq'}>FAQ</a></li>
                    </ul>
                </div>
                <div class="footer-text">© 2024 FILESHIBA. All rights reserved.</div>
            </footer>

        </>
    );
};

export default Footer;