/* eslint-disable no-undef */
import '../App.css';
import { Web3Button, Web3Modal, useWeb3Modal } from '@web3modal/react';
import { mainnet, useAccount, useContractRead, useContractReads, useContractWrite, useNetwork, usePublicClient, useSwitchNetwork } from 'wagmi';
import { createPublicClient, formatEther, http, parseEther } from 'viem';
import Countdown from 'react-countdown';
//import { filecoin } from 'wagmi/chains';
import { filecoin } from 'wagmi/chains';
import React, { useEffect, useMemo, useState } from 'react';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import paw from '../assets/paw.png';
import logo from '../assets/logo.png';
import boxI from '../assets/lock-phone.png';
import boxI2 from '../assets/star.png';
import boxI3 from '../assets/insurance.png';
import { ethers } from 'ethers';
import check from '../assets/check.gif';
import { error } from 'jquery';
import Footer from '../Components/Footer';
import down from '../assets/down.png';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
	/*	if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {*/
	// Render a countdowns

	if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {

		window.location.reload();
		console.log("Mint Begins");
	}


	return <div class="counterBlock"><div class="days">{days}</div><div class="dots">:</div><div class="days">{hours}</div><div class="dots">:</div><div class="days">{minutes}</div><div class="dots">:</div><div class="sec">{seconds}</div></div>;
	/*	}*/
};

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const ops = () => {
	window.open("#");
}

const discordL = () => {
	window.open("https://discord.gg/tknBv7Xwuj");
}
/*
let ABI = [
	{
		"inputs": [],
		"name": "borrow",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Borrowed",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "calculateInterest",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "calculateRewards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Claimed",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "claimRewards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "depositCollateral",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Repaid",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "repay",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_collateralRate",
				"type": "uint256"
			}
		],
		"name": "setCollateralRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_dailyInterestRate",
				"type": "uint256"
			}
		],
		"name": "setDailyInterestRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardRatePerSecond",
				"type": "uint256"
			}
		],
		"name": "setRewardRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardRatePerSecondDivider",
				"type": "uint256"
			}
		],
		"name": "setRewardRatePerSecondDivider",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "stake",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Staked",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unstake",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Unstaked",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "withdrawETH",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawTokens",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "borrowedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "borrowedTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "collateralAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "collateralRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "dailyInterestRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalNumber",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "FSHIB",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getBorrowLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getContractBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getInterestTimeElapsed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getInterestUpToNow",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getTimeElapsed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "interestPaymentUpToNow",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "repaymentCollateralAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardRatePerSecond",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardRatePerSecondDivider",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "rewardsEarned",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "stakedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "stakedTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalRewardsClaimed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalStaked",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];
let address = "0x44088Ca31Cc87F4a2d0842e94b2876de34514793"*/

let ABI = [
	{
		"inputs": [],
		"name": "borrow",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Borrowed",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "calculateInterest",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "calculateRewards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Claimed",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "claimRewards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "depositCollateral",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Repaid",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "repay",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_collateralRate",
				"type": "uint256"
			}
		],
		"name": "setCollateralRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_dailyInterestRate",
				"type": "uint256"
			}
		],
		"name": "setDailyInterestRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardRatePerSecond",
				"type": "uint256"
			}
		],
		"name": "setRewardRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardRatePerSecondDivider",
				"type": "uint256"
			}
		],
		"name": "setRewardRatePerSecondDivider",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "stake",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Staked",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unstake",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Unstaked",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "withdrawCollatoral",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdrawETH",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_token",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			}
		],
		"name": "withdrawTokens",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "borrowedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "borrowedTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "collateralAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "collateralRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "dailyInterestRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimalNumber",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "FSHIB",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getBorrowLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getContractBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getInterestTimeElapsed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getInterestUpToNow",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			}
		],
		"name": "getTimeElapsed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "interestPaymentUpToNow",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "repaymentCollateralAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardRatePerSecond",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardRatePerSecondDivider",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "rewardsEarned",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "stakedAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "stakedTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalRewardsClaimed",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalStaked",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

let address = "0xDa3fF122c174D096C86fff4130BDfd0692927A62";

//TOKEN
let ABI2 = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "name_",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "symbol_",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "decimals_",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "initialBalance_",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_txFee",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_burnFee",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_FeeAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "tokenOwner",
				"type": "address"
			},
			{
				"internalType": "address payable",
				"name": "feeReceiver_",
				"type": "address"
			}
		],
		"stateMutability": "payable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "FeeAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "Optimization",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "_owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "burnFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "tokenAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenAmount",
				"type": "uint256"
			}
		],
		"name": "recoverERC20",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "recipient",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "recipient",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "txFee",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_txFee",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_burnFee",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "_FeeAddress",
				"type": "address"
			}
		],
		"name": "updateFee",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];

let address2 = "0x47F4A815032522A4BA912BB87Dc7eBD05eA54974";


const Home = () => {

	const { open } = useWeb3Modal()
	const { chain } = useNetwork()
	const [accountBalance, setAccountBalance] = useState(null);

	const { switchNetwork } = useSwitchNetwork()
	const clientID = "2f8f716d-b303-481b-80ef-febaf341524b";
	const [ethBalance, setEthBalance] = useState(null);
	const [walletAmount, setWalletAmount] = useState('');
	const [enteredAmount, setEnteredAmount] = useState('');
	const [enteredAmount2, setEnteredAmount2] = useState('');
	const [isAmountExceeding, setIsAmountExceeding] = useState(false);
	const [_collateralAmount, setCollateralAmount] = useState(0);

	const handleMaxAmount = () => {
		setEnteredAmount(walletAmount);
		setIsAmountExceeding(false); // Reset the error state
	};

	const { address: walletAddress } = useAccount({
		async onConnect() {
			handleConnect()
		}
	})

	const publicClient = createPublicClient({
		chain: filecoin,
		transport: http()
	})

	const [_rewardRatePerSecond, setRewardRatePerSecond] = useState(0)
	const [_rewardRatePerSecondDivider, setRewardRatePerSecondDivider] = useState(0)
	const [_timeElapsed, setTimeElapsed] = useState(0)
	const [statusError, setstatusError] = useState(false)
	const [statusLoading, setstatusLoading] = useState(false)
	const [success, setsuccess] = useState("")
	const [_owner, set_owner] = useState("")
	const [_navbarOpen, set_navbarOpen] = useState(0)
	const [_deposit, set_depositOpen] = useState(1)
	const [_claim, set_claimOpen] = useState(0)
	const [_withdraw, set_withdrawOpen] = useState(0)
	const [_Collateral, set_Collateral] = useState(1)
	const [_BarrowOpen, set_Barrow] = useState(0)
	const [_withdrwCollatoral, set_WithdrwCollatoral] = useState(0)
	const [_Repay, set_Repay] = useState(0)
	const [_connected, setConnected] = useState(false)
	const [_quantity, set_quantity] = useState(0);
	const [_quantityC, set_quantityC] = useState(0);
	const [_receiveC, set_receiveC] = useState(0);
	const [_receive, set_receive] = useState(0);
	const [_rewardsEarned, set_rewardsEarned] = useState(0);
	const [_getBorrowLimit, setBorrowLimit] = useState(0);
	const [_stakedTime, setStakedTime] = useState(0);
	const [_borrowedAmount, setBorrowedAmount] = useState(0);
	const [_stakedAmount, setStakedAmount] = useState(0);
	const [_shibaBalance, setShibaBalance] = useState(0);
	const [_repaymentCollateralAmount, setRepaymentCollateralAmount] = useState(0);
	const [_interestUpToNow, setInterestUpToNow] = useState(0);
	const [_getRepayAmount, setRepayment] = useState(0);
	const [_borrowedTime, setBorrowedTime] = useState(0);
	const [_currentAllowance, setCurrentAllowance] = useState(0);
	const [_allowanceStatus, setAllowanceStatus] = useState(false);
	const [_allowanceStatus2, setAllowanceStatus2] = useState(false);
	const [_increaseAllowanceAmount, setIncreaseAllowanceAmount] = useState(0);
	const [_getContractBalance, set_getContractBalance] = useState(0);
	const [_totalRewardsClaimed, set_totalRewardsClaimed] = useState(0);

	async function closeNav() {
		set_navbarOpen(0);
		//this.setState({ _navbarOpen: 0 });
	}

	async function closeWindow2() {

		setstatusError(false);

	}

	async function navbarOpen() {
		set_navbarOpen(1);
		//this.setState({ _navbarOpen: 0 });
	}

	async function depositOpen() {
		set_withdrawOpen(0);
		set_claimOpen(0);
		set_depositOpen(1);
	}

	async function testNotification() {
		setsuccess(true);
		console.log("success : " + success);
	}

	async function claimOpen() {

		set_withdrawOpen(0);
		set_claimOpen(1);
		set_depositOpen(0);

		set_rewardsEarned(((_stakedAmount * (_rewardRatePerSecond * (Date.now() - _stakedTime)) / 1000)) / 100000000000);
		console.log("_stakedAmount : " + _stakedAmount);
		console.log("_rewardRatePerSecond : " + _rewardRatePerSecond);

		console.log("STAKE TIME : " + Number(Date.now() - _stakedTime));
		console.log("Date : " + Date.now());
		console.log("_stakedTime : " + _stakedTime);
	}

	async function withdrawOpen() {
		set_withdrawOpen(1);
		set_claimOpen(0);
		set_depositOpen(0);
	}

	async function CollateralOpen() {
		set_Collateral(1);
		set_Barrow(0);
		set_Repay(0);
		set_WithdrwCollatoral(0);
	}

	async function withdrawCollatoralOpen() {
		set_Collateral(0);
		set_Barrow(0);
		set_Repay(0);
		set_WithdrwCollatoral(1);
	}

	async function allowance() {

		try {
			setstatusLoading(true);

			console.log("Number(_currentAllowance)" + Number(_currentAllowance));
			console.log("(Number(_getRepayAmount * 2.5)" + Number(_getRepayAmount * 2.5));

			//const increaseAllowanceAmount = Number(_getRepayAmount) - Number(_currentAllowance);
			setIncreaseAllowanceAmount(Number(Number(_getRepayAmount * 2.5) - Number(_currentAllowance)/10**18) * 10 ** 18)
			var increaseAllowanceAmount = (Number(Number(_getRepayAmount * 2.5) - Number(_currentAllowance)/10**18) * 10 ** 18).toFixed(0);
			console.log("increaseAllowanceAmount : " + _increaseAllowanceAmount);
			console.log("_getRepayAmount : " + _getRepayAmount);
			console.log("data.data : " + _currentAllowance);

			var res = await writeAsync({
				...contract2,
				functionName: 'increaseAllowance',
				args: [address, increaseAllowanceAmount],

				//value: '100000000000000000',
				gasLimit: '685000'
			})
			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();

				await new Promise(resolve => setTimeout(resolve, 2000));
				window.location.reload();
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function allowance2() {

		try {
			setstatusLoading(true);

			console.log("_quantity : " + _quantity);
			console.log("_currentAllowance : " + _currentAllowance);

			//const increaseAllowanceAmount = Number(_getRepayAmount) - Number(_currentAllowance);

			setIncreaseAllowanceAmount((Number(_quantity * 10 ** 18) - Number(_currentAllowance)));
			console.log("data.data : " + _currentAllowance);

			const _increaseAllowance = ((Number(_quantity * 10 ** 18) - Number(_currentAllowance)));
			console.log("Amount increasing :" + _increaseAllowance);
			const rounded = Math.round(_increaseAllowance);


			var res = await writeAsync({
				...contract2,
				functionName: 'increaseAllowance',
				args: [address, rounded],

				//value: '100000000000000000',
				gasLimit: '685000'
			})
			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function BarrowOpen() {

		const data = await getBorrowLimit();
		console.log("getBorrowLimit : " + Number(data.data));

		setBorrowLimit(Number(data.data) / 10 ** 18);
		set_Collateral(0);
		set_Barrow(1);
		set_Repay(0);
		set_WithdrwCollatoral(0);
	}

	async function RepayOpen() {
		set_Collateral(0);
		set_Barrow(0);
		set_Repay(1);
		set_WithdrwCollatoral(0);
	}

	//........................................//
	var contract = {
		address: address,
		abi: ABI
	}

	var contract2 = {
		address: address2,
		abi: ABI2
	}

	async function handleConnect() {
		if (chain.id !== 314) {
			switchNetwork(314)
		}

		setConnected(true);
	}

	const { refetch: getRewardRatePerSecond } = useContractRead({
		...contract,
		functionName: 'rewardRatePerSecond'/*, args: [0]*/
	})

	const { refetch: getRewardRatePerSecondDivider } = useContractRead({
		...contract,
		functionName: 'rewardRatePerSecondDivider'/*, args: [0]*/
	})

	const { refetch: getBorrowLimit } = useContractRead({
		...contract,
		functionName: 'getBorrowLimit', args: [walletAddress]
	})

	const { refetch: getTimeElapsed } = useContractRead({
		...contract,
		functionName: 'getTimeElapsed', args: [walletAddress]
	})

	const { refetch: getBorrowedAmount } = useContractRead({
		...contract,
		functionName: 'borrowedAmount', args: [walletAddress]
	})


	const { refetch: getInterestUpToNow } = useContractRead({
		...contract,
		functionName: 'getInterestUpToNow', args: [walletAddress]
	})



	const { refetch: getRewardsEarned } = useContractRead({
		...contract,
		functionName: 'rewardsEarned', args: [walletAddress]
	})

	const { refetch: getStakedTime } = useContractRead({
		...contract,
		functionName: 'stakedTime', args: [walletAddress]
	})

	const { refetch: getStakedAmount } = useContractRead({
		...contract,
		functionName: 'stakedAmount', args: [walletAddress]
	})

	const { refetch: getCollateralAmount } = useContractRead({
		...contract,
		functionName: 'collateralAmount', args: [walletAddress]
	})

	const { refetch: getAllowance } = useContractRead({
		...contract2,
		functionName: 'allowance', args: [walletAddress, address]
	})

	async function fetchStakeTime() {
		var data1 = await getStakedTime();

		setStakedTime(Number(data1.data) * 1000);
		console.log("setStakedTime : " + Number(data1.data) * 1000);
		console.log("DATE : " + Date.now());

	}

	async function fetchBorrowedAmount() {
		var data2 = await getBorrowedAmount();

		setBorrowedAmount(Number(data2.data));

	}

	async function fetchInterestUpToNow() {
		try {
			var data3 = await getInterestUpToNow();

			setInterestUpToNow(Number(data3.data));

		} catch (err) {
			console.log("err" + err)
		}
	}

	const { refetch: getBalance } = useContractRead({
		...contract2,
		functionName: 'balanceOf',
		args: [walletAddress ? walletAddress : '0x']
	})

	const { refetch: getRepaymentCollateralAmount } = useContractRead({
		...contract,
		functionName: 'repaymentCollateralAmount',
		args: [walletAddress]
	})

	const { refetch: getContractBalance } = useContractRead({
		...contract,
		functionName: 'getContractBalance'
	})

	const { refetch: getTotalRewardsClaimed } = useContractRead({
		...contract,
		functionName: 'totalRewardsClaimed'
	})

	useEffect(() => {

		/*if (walletAddress) {
			getAccountBalance();
		}*/

		/*async function fetchWalletAmount() {
			try {
				// Ensure MetaMask is available
				if (window.ethereum) {
					// Create a provider using MetaMask
					const provider = new ethers.providers.Web3Provider(window.ethereum);
					// Get the current account address
					const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
					const account = accounts[0];
					// Get the balance of the account
					const balance = await provider.getBalance(account);
					// Convert the balance from Wei to Ether
					const etherBalance = ethers.utils.formatEther(balance);
					// Update the wallet amount state
					setWalletAmount(etherBalance);
				} else {
					console.log('MetaMask not available');
				}
			} catch (error) {
				console.error('Error fetching wallet amount:', error);
			}
		}*/


		async function fetchRewards() {
			var data = await getRewardRatePerSecond();

			setRewardRatePerSecond(Number(data.data));
			console.log("rewardRatePerSecond : " + data.data);

		}

		async function fetchRewardsDivider() {
			var data = await getRewardRatePerSecondDivider();

			setRewardRatePerSecondDivider(Number(data.data));
			console.log("setRewardRatePerSecondDivider : " + data.data);

		}

		async function fetchTime() {
			var data1 = await getTimeElapsed();

			setTimeElapsed(Number(data1.data) * 1000);
			console.log("TimeElapsed : " + Number(data1.data) * 1000);

		}

		async function fetchBorrowedTime() {
			var data2 = await getTimeElapsed();

			setBorrowedTime(Number(data2.data) * 1000);
			console.log("fetchBorrowedTime : " + _borrowedTime)
		}

		async function fetchStakedAmount() {
			var data2 = await getStakedAmount();

			setStakedAmount(Number(data2.data));
			console.log("setStakedAmount : " + Number(data2.data));

		}

		async function fetchShiba() {
			var data3 = await getBalance();

			setShibaBalance(Number(data3.data) / 10 ** 18);

		}


		async function fetchRepaymentCollateralAmount() {
			var data3 = await getRepaymentCollateralAmount();

			setRepaymentCollateralAmount(Number(data3.data) / 10 ** 18);
			console.log("RepaymentCollateralAmount : " + data3.data);
		}

		async function fetchCollateralAmount() {
			var data4 = await getCollateralAmount();

			setCollateralAmount(Number(data4.data) / 10 ** 18);

		}

		async function calculateRepayment() {

			setRepayment((Number(_borrowedAmount) + Number(_interestUpToNow)) / 10 ** 18);
			console.log("replayment :" + _getRepayAmount);
			console.log("_borrowedAmount : " + _borrowedAmount);
			console.log("_interestUpToNow : " + _interestUpToNow);
		}

		async function fetchGetContractBalance() {
			var data1 = await getContractBalance();
			set_getContractBalance(data1.data);
		}

		async function fetchTotalRewardsClaimed() {
			var data1 = await getTotalRewardsClaimed();
			set_totalRewardsClaimed(Number(data1.data) / 1000000000000000000);
		}

		async function allowanceCheck() {

			console.log("allowanceCheck1");
			const data = await getAllowance();
			setCurrentAllowance(Number(data.data));
			console.log("#1._getRepayAmount : " + _getRepayAmount * 10 ** 18);
			console.log("#2._Current Allowance897 : " + data.data);
			console.log("#3._quantity : " + _quantity * 10 ** 18);

			console.log("allowanceCheck2");

			if (Number(data.data) >= Number(_getRepayAmount * 10 ** 18) || (Number(data.data) >= Number(_quantity) * 10 ** 18)) {
				setAllowanceStatus(true);
				//Show the repay button
				console.log("setAllowanceStatus : " + setAllowanceStatus);
				console.log("allowanceCheck3");

			} else {
				setAllowanceStatus(false);
				//Show the approve button
				console.log("allowanceCheck4");

			}
		}

		async function allowanceCheck2() {

			console.log("allowanceCheck2");
			const data = await getAllowance();
			setCurrentAllowance(Number(data.data));
			console.log("#1._getRepayAmount2 : " + _getRepayAmount * 10 ** 18);
			console.log("#2._Current Allowance897__2 : " + data.data);
			console.log("#3._quantity2 : " + _quantity * 10 ** 18);

			console.log("allowanceCheck2__2");

			if (Number(data.data) >= Number(_getRepayAmount * 10 ** 18)){
				setAllowanceStatus2(true);
				//Show the repay button
				console.log("setAllowanceStatus2 : " + setAllowanceStatus2);
				console.log("allowanceCheck3__2");

			} else {
				setAllowanceStatus2(false);
				//Show the approve button
				console.log("allowanceCheck4__2");

			}
		}


		set_receive((_quantity * _rewardRatePerSecond * 86400) / _rewardRatePerSecondDivider);

		if (_connected) {
			fetchRewards();
			fetchTime();
			fetchStakeTime();
			fetchStakedAmount();
			fetchShiba();
			//fetchWalletAmount();
			fetchBorrowedAmount();
			fetchBorrowedTime();
			/*if (_borrowedTime > 0) {
				console.log("I'm in : ");
				
			}*/
			fetchInterestUpToNow();
			calculateRepayment();
			fetchCollateralAmount();
			allowanceCheck();
			allowanceCheck2();
			fetchGetContractBalance();
			fetchTotalRewardsClaimed();
			fetchRepaymentCollateralAmount();
			fetchRewardsDivider();

		}

		// eslint-disable-next-line no-use-before-define
	}, [walletAddress, _connected, _quantity, _quantityC
		, _borrowedAmount, _interestUpToNow /*getRewardRatePerSecond, _rewardRatePerSecond, _receive*/]);

	/*const { data, refetch, isSuccess } = useContractReads({
		contracts: [
			{ ...contract, functionName: 'totalSupply', args: [0] },
			{ ...contract, functionName: 'max_per_wallet' },
			{ ...contract, functionName: 'publicSaleCost', args: [0] },
			//{ ...contract, functionName: 'public_mint_status' }

		]
	},)*/

	/*useMemo(() => {

		if (isSuccess === true) {
			console.log("isSuccess : " + isSuccess);
			//settotalSupply(Number(data[0].result));
			settotalSupply(Number(data[0].result));
			console.log("settotalSupply : " + settotalSupply);
			//set_max_per_wallet(Number(data[1].result));
			set_max_per_wallet(Number(data[1].result));
			console.log("set_max_per_wallet : " + set_max_per_wallet);
			//set_costGateway(Number(data[4].result) / 10 ** 4); // Convert WEI to ETH
			set_costGateway(Number(data[4].result) / 10 ** 4); // Convert WEI to ETH
			console.log("set_costGateway : " + set_costGateway);
			//set_public_mint_status(data[5].result);
		}
	}, [_totalSupply, data, isSuccess])*/

	/*async function getAccountBalance() {
		try {
			// Create a new Web3Provider using window.ethereum
			let provider = new ethers.providers.Web3Provider(window.ethereum);

			// Fetch account balance
			let balance = await provider.getBalance(walletAddress);

			// Format balance from Wei to Ether
			balance = ethers.utils.formatEther(balance);

			// Set account balance in state
			setAccountBalance(balance.substring(0, 4));
		} catch (error) {
			console.error('Error fetching account balance:', error);
		}
	}*/

	const { writeAsync } = useContractWrite({
		//...contract,
		//functionName: 'mint',
		onError(error) {
			if (error.message.includes('balance')) {
				setstatusError(true)
				setstatusLoading(false)
			}
		}
	})

	//........................................//


	async function maxAmount() {
		try {

			set_rewardsEarned((_stakedAmount * (_rewardRatePerSecond * (Date.now() - _stakedTime - 60000)) / 1000));
			//set_quantity(_stakedAmount / 10 ** 18);

			set_quantity((Number((_stakedAmount * (_rewardRatePerSecond * (Date.now() - _stakedTime - 60000)) / 1000)) + Number(_stakedAmount)));


		} catch (err) {
			console.log(err);
		}
	}


	async function stake() {
		try {
			setstatusLoading(true);
			const valueToSend = _quantity * 10 ** 18; // Example: Sending 1 ETH
			console.log("quantity : " + _quantity);
			var res = await writeAsync({
				...contract,
				functionName: 'stake',
				args: [_quantity.toString()],
				//value: ((valueToSend).toString()), ////

				//value: '100000000000000000',
				gasLimit: '685000'
			})

			var result = await publicClient.waitForTransactionReceipt(res)
			if (result.status === 'success') {
				setstatusError(false)
				setsuccess(true)
				setstatusLoading(false)
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			}
			else {
				setsuccess(false)
				setstatusError(true)
				setstatusLoading(false)
			}
		} catch (err) {
			setsuccess(false)
			setstatusError(true)
			setstatusLoading(false)
			console.log(err);
		}

	}

	async function borrow() {
		try {
			setstatusLoading(true);
			// Call your contract's claimRewards function
			const res = await writeAsync({
				...contract,
				functionName: 'borrow',
				gasLimit: '300000', // Adjust gas limit as needed
			});

			// Wait for transaction receipt
			const result = await publicClient.waitForTransactionReceipt(res);

			// Handle success or failure
			if (result.status === 'success') {
				setstatusError(false);
				setsuccess(true);
				setstatusLoading(false);
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();

				await new Promise(resolve => setTimeout(resolve, 2000));
				window.location.reload();
			} else {
				setsuccess(false);
				setstatusError(true);
				setstatusLoading(false);
			}
		} catch (error) {
			// Handle error
			setsuccess(false);
			setstatusError(true);
			setstatusLoading(false);
			console.error('Error claiming rewards:', error);
			// You may want to set error state here if needed
		}
	}

	async function repay() {
		try {
			setstatusLoading(true);
			if (_borrowedAmount !== 0) {

				// Call your contract's claimRewards function
				const res = await writeAsync({
					...contract,
					functionName: 'repay',
					gasLimit: '300000', // Adjust gas limit as needed
				});

				// Wait for transaction receipt
				const result = await publicClient.waitForTransactionReceipt(res);

				// Handle success or failure
				if (result.status === 'success') {
					setstatusError(false);
					setsuccess(true);
					setstatusLoading(false);
					await new Promise(resolve => setTimeout(resolve, 15000));
					window.location.reload();
				} else {
					setsuccess(false);
					setstatusError(true);
					setstatusLoading(false);
				}
			} else {
				setRepayment(0);
			}
		} catch (error) {
			// Handle error
			setsuccess(false);
			setstatusError(true);
			setstatusLoading(false);
			console.error('Error claiming rewards:', error);
			// You may want to set error state here if needed
		}
	}

	async function depositCollateral() {
		try {
			setstatusLoading(true);
			const valueToSend = enteredAmount2 * 10 ** 18; // Example: Sending 1 ETH
			// Call your contract's claimRewards function
			const res = await writeAsync({
				...contract,
				functionName: 'depositCollateral',
				value: ((valueToSend).toString()), ////
				gasLimit: '300000', // Adjust gas limit as needed
			});

			// Wait for transaction receipt
			const result = await publicClient.waitForTransactionReceipt(res);

			// Handle success or failure
			if (result.status === 'success') {
				setstatusError(false);
				setsuccess(true);
				setstatusLoading(false);
				console.log("error : " + statusError);
				console.log("loading : " + statusLoading);
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			} else {
				setsuccess(false);
				setstatusError(true);
				setstatusLoading(false);
			}
		} catch (error) {
			// Handle error
			setsuccess(false);
			setstatusError(true);
			setstatusLoading(false);
			console.error('Error claiming rewards:', error);
			// You may want to set error state here if needed
		}
	}

	async function unstake() {

		const amount = Number(_quantity) * 10 ** 18;
		try {

			setstatusLoading(true);
			// Call your contract's unstake function
			const res = await writeAsync({
				...contract,
				functionName: 'unstake',
				//args: [BigInt(amount)], // Pass the amount to unstake
				gasLimit: '685000', // Adjust gas limit as needed
			});


			// Wait for transaction receipt
			const result = await publicClient.waitForTransactionReceipt(res);

			// Handle success or failure
			if (result.status === 'success') {
				setstatusError(false);
				setsuccess(true);
				setstatusLoading(false);
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();



			} else {
				setsuccess(false);
				setstatusError(true);
				setstatusLoading(false);
			}


		} catch (error) {
			// Handle error
			setsuccess(false);
			setstatusError(true);
			setstatusLoading(false);
			console.error('Error unstaking:', error);
			// You may want to set error state here if needed
		}
	}

	async function withdrawCollatoral() {
		try {
			setstatusLoading(true);
			// Call your contract's claimRewards function
			const res = await writeAsync({
				...contract,
				functionName: 'withdrawCollatoral',
				gasLimit: '300000', // Adjust gas limit as needed
			});

			// Wait for transaction receipt
			const result = await publicClient.waitForTransactionReceipt(res);

			// Handle success or failure
			if (result.status === 'success') {
				setstatusError(false);
				setsuccess(true);
				setstatusLoading(false);
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			} else {
				setsuccess(false);
				setstatusError(true);
				setstatusLoading(false);
			}
		} catch (error) {
			// Handle error
			setsuccess(false);
			setstatusError(true);
			setstatusLoading(false);
			console.error('Error claiming rewards:', error);
			// You may want to set error state here if needed
		}
	}

	async function claimRewards() {
		try {
			setstatusLoading(true);
			// Call your contract's claimRewards function
			const res = await writeAsync({
				...contract,
				functionName: 'claimRewards',
				gasLimit: '300000', // Adjust gas limit as needed
			});

			// Wait for transaction receipt
			const result = await publicClient.waitForTransactionReceipt(res);

			// Handle success or failure
			if (result.status === 'success') {
				setstatusError(false);
				setsuccess(true);
				setstatusLoading(false);
				await new Promise(resolve => setTimeout(resolve, 15000));
				window.location.reload();
			} else {
				setsuccess(false);
				setstatusError(true);
				setstatusLoading(false);
			}
		} catch (error) {
			// Handle error
			setsuccess(false);
			setstatusError(true);
			setstatusLoading(false);
			console.error('Error claiming rewards:', error);
			// You may want to set error state here if needed
		}
	}

	const isExceedingStakedAmount = () => {
		const enteredAmount = Number(_quantity);
		console.log("entered Amount : " + enteredAmount);
		console.log("_quantity Amount : " + _stakedAmount);
		return enteredAmount * 10 ** 18 > _stakedAmount;
	};


	return (
		<>
			<div class="allWrap2">
				<div class="light">

					<div class="headers">

						<div class="h1">
							<div class="logoDiv">
								<img onClick={() => window.location.href = '/'} src={logo} />

								<div class="connect">
									<div onClick={() => window.location.href = 'stake'} id="fontSize"><span className='activeNav'>Stake</span></div>
									<div onClick={() => window.location.href = 'multisig'} id="fontSize" >Multisig</div>
									<div onClick={() => window.location.href = 'wallet'} id="fontSize">Wallet</div>
									<div id="fontSize" >FAQ</div>
								</div>

							</div>

							<div className='web3'><Web3Button /></div>

						</div>

					</div>

					{_navbarOpen < 1 ?
						(<div class="miniBarMain">
							<div class="logoDiv"><img src={logo} /></div>

							<div className='web3'><Web3Button /></div>

							<div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
						</div>) :
						(<div class="miniBarMain">
							<div class="logoDiv"><img src={logo} /></div>

							<div className='web3'><Web3Button /></div>

							<div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
						</div>)}

					{_navbarOpen > 0 ?
						(<div class="littleNav">
							<div onClick={() => window.location.href = 'stake'} id="fontSize"><span className='activeNav'>Stake</span></div>
							<div onClick={() => window.location.href = 'multisig'} id="fontSize" >Multisig</div>
							<div onClick={() => window.location.href = 'wallet'} id="fontSize">Wallet</div>
							<div id="fontSize" >FAQ</div>

						</div>) : null}


					<div className='stake'>
						<div className='stakeMain'>
							<div class="box1">
								<div>
									<div class="stakeH">FILE TVL</div>
									<div class="stakeP">{Number(_getContractBalance) / 10 ** 18} FIL</div>
								</div>

								<img src={boxI} />
							</div>

							<div class="box1">
								<div>
									<div class="stakeH">Total FSHIB Paid</div>
									<div class="stakeP">{Number(_totalRewardsClaimed)}</div>
								</div>

								<img src={boxI2} />
							</div>

							<div class="box1">
								<div>
									<div class="stakeH">Security Audited Coming Soon</div>
									{/*<div class="stakeH2">Audited by ConsenSys Diligence <br /> and Sherlock</div>*/}
								</div>

								<img src={boxI3} />
							</div>
						</div>

						<div className='stakeBox2Flex'>
							<div className='stakeBox'>
								<div>
									<div className='balance'>
										<div>
											<div><span id="balance2">FSHIB Balance</span></div>
											<div id="balance">{_shibaBalance.toFixed(2)}</div>
										</div>

										<div>
											<div><span id="balance2">Rewards Rate (Per Day)</span></div>
											<div id="balance">{((_rewardRatePerSecond) * 86400 / _rewardRatePerSecondDivider).toFixed(4)} FSHIB</div>
										</div>
									</div>
									<div className='line'></div>
									<div className='balance'>
										<div>
											<div><span id="balance2">MY Balance</span></div>
											{/*<div id="balance">{accountBalance !== null ? `${accountBalance} FIL` : '0'}</div>*/} {/* Display Ethereum balance */}
										</div>
									</div>
								</div>
								<div className='stakeNav'>
									{_deposit > 0 ?
										<div id='onStake' onClick={depositOpen}>Deposit</div> :
										<div onClick={depositOpen}>Deposit</div>
									}

									{_claim > 0 ?
										<div id='onStake' onClick={claimOpen}>Claim</div> :
										<div onClick={claimOpen}>Claim</div>
									}

									{_withdraw > 0 ?
										<div id='onStake' onClick={withdrawOpen}>Withdraw</div> :
										<div onClick={withdrawOpen}>Withdraw</div>
									}
								</div>

								{_deposit > 0 ?
									<div className='stakeBox2'>
										<div className='inputSection'>
											<input placeholder='Amount' type='number' value={_quantity}

												onChange={event => {
													const newValue = event.target.value;
													// Check if the new value is a valid number and higher than 0
													if (!isNaN(newValue) && parseFloat(newValue) >= 0) {
														set_quantity(newValue);
													}
												}}
											></input>
											<div placeholder='Receive'>FSHIB</div>
										</div>

										<img className='downA' src={down} />

										<div className='inputSection2'>
											<input readOnly placeholder='Receive' type='number' value={_receive}
												onChange={event => set_receive(event.target.value)}></input>
											<div placeholder='Receive'>FSHIB</div>
										</div>
										<div className='note'>*Rewards per day</div>

										<div className='depositMain'>
											{_allowanceStatus ?
												(<button className='deposit' onClick={stake}>Deposit</button>) :
												(<button className='deposit' onClick={allowance2}>Approve</button>)
											}
										</div>

									</div>
									: null}

								{_claim > 0 ?
									<div className='stakeBox2'>

										<div className='rewards'>You have earned {_rewardsEarned.toFixed(2)} FSHIB</div>

										<div className='depositMain'><button className='deposit' onClick={claimRewards}>Claim</button></div>

									</div> : null}

								{_withdraw > 0 ?
									<div className='stakeBox2'>
										{/*<div className='inputSection'>
											<input
												placeholder='Amount'
												type='number'
												value={_quantity}
												onChange={event => {
													const newValue = event.target.value;
													// Check if the new value is a valid number and higher than 0
													if (!isNaN(newValue) && parseFloat(newValue) >= 0) {
														set_quantity(newValue);
													}
												}}
												style={{ color: isExceedingStakedAmount() ? 'rgb(255, 79, 79)' : 'white' }}
											/>
											<button className='max' onClick={maxAmount}>MAX</button>

											<div placeholder='Receive'>FIL</div>
											</div>*/}

										<div className='depositMain'>
											<button className='deposit' onClick={unstake}>Withdraw</button>
										</div>

									</div> : null}
							</div>

							<div className='stakeBox'>
								<div>
									<div className='balance'>
										<div>
											<div><span id="balance2">Total Collateral</span></div>
											<div id="balance">{isNaN(_repaymentCollateralAmount) ? '0' : Number(_repaymentCollateralAmount)} FIL</div>
										</div>

										<div>
											<div><span id="balance2">Interest Rate</span></div>
											<div id="balance">5% <span className='hl2'>Daily</span></div>
										</div>
									</div>
									<div className='line'></div>
									<div className='balance'>
										<div>
											<div><span id="balance2">Borrowed</span></div>
											<div id="balance">{_borrowedAmount / 1000000000000000000} FSHIB</div> {/* Display Ethereum balance */}
										</div>
									</div>
								</div>

								{_collateralAmount === 0 && _repaymentCollateralAmount > 0 ?

									<div>
										<div className='stakeNav--2'>
											<div>Repayment</div>
										</div>

										<div className='stakeBox2'>

											<div className='rewards'>{_getRepayAmount.toFixed(9)} FSHIB</div>

											<div className='depositMain'>

												{_allowanceStatus2 ?
													(<button className='deposit' onClick={repay}>Repay</button>) :
													(<button className='deposit' onClick={allowance}>Approve</button>)
												}

											</div>

										</div>

									</div> :

									<div>
										<div className='stakeNav'>

											{_Collateral > 0 ?
												<div id='onStake' onClick={CollateralOpen}>Collateral</div> :
												<div onClick={CollateralOpen}>Collateral</div>
											}

											{_BarrowOpen > 0 ?
												<div id='onStake' onClick={BarrowOpen}>Borrow</div> :
												<div onClick={BarrowOpen}>Borrow</div>
											}

											{_withdrwCollatoral > 0 && _borrowedAmount <= 0 ?
												<div id='onStake' onClick={withdrawCollatoralOpen}>Withdraw</div> :
												<div onClick={withdrawCollatoralOpen}>Withdraw</div>
											}

										</div>

										{_Collateral > 0 ?

											<div className='stakeBox2'>
												<div className='inputSection'>
													<input
														placeholder='Amount'
														type='number'
														value={enteredAmount2}
														onChange={(event) => {
															const newValue = event.target.value;
															// Check if the new value is a valid number and higher than 0
															if (!isNaN(newValue) && parseFloat(newValue) >= 0) {
																setEnteredAmount2(newValue);
																setIsAmountExceeding(parseFloat(newValue) > parseFloat(walletAmount)); // Check if entered amount exceeds wallet amount
															}
														}}
														className={isAmountExceeding ? 'inputError' : ''}
													/>

													<div placeholder='Receive'>FIL</div>
												</div>

												<div className='depositMain'>
													<button className='deposit' onClick={depositCollateral}>Collateral</button>
												</div>

											</div> : null}

										{_BarrowOpen > 0 ?
											<div className='stakeBox2'>

												<div className='rewards'>{_getBorrowLimit.toFixed(2)} FSHIB</div>

												<div className='depositMain'><button className='deposit' onClick={borrow}>Borrow</button></div>

											</div> : null}

										{_withdrwCollatoral > 0 ?
											
											< div className='stakeBox2'>

										<div className='rewards'>{_collateralAmount.toFixed(2)} FIL</div>

										<div className='depositMain'><button className='deposit' onClick={withdrawCollatoral}>Withdraw</button></div>

									</div> : null}

									</div>}
							</div>
						</div>
					</div>


				</div>

				<img className='paw' src={paw} />

			</div >


			<div>

				{statusError ?
					(<div class="formMain2">
						<form onClick={closeWindow2}>
							<button class="closeNote">✖</button>
						</form>
						<div class="formMain-2">

							<div>
								<div class="errorMessage">
									Sorry, something went wrong please try again later
								</div>
							</div>
						</div>

					</div>) : null}

				{statusLoading ?
					(<div class="formMain2">
						<div class="formMain-2">

							<div class="loader"></div>

						</div>

					</div>) : null}

				{success ?
					(<div class="formMain2">
						<div class="formMain-2">

							<div>
								<img class="check" src={check} />

								<div class="success-message">
									<h2>Transaction Successful!</h2>
									<h2>Awaiting updates...</h2>
								</div>
							</div>
						</div>


					</div>) : null}

				<Footer />

			</div>
		</>
	);

}
export default Home;
